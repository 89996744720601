import axios from 'axios';
import config from '../config';
import Parcel from '../services/parcel';
import Land from '../services/land';
import { downloadFile } from '.';
import { parseTimestamp } from '../utils/firebase';
import { parseGeoJSON } from '../utils/map';
import Activity from '../services/activity';
import { CMS } from '../services/cms';
const api = config.api();

const isLandOnlyParcel = parcelType => {
  const parcelTypes = ['openSpace', 'lotWetland', 'lotBuildable'];
  return parcelTypes.includes(parcelType);
};

const NEARBY_PARCEL_MAX_DISTANCE = 500; //yd

const calcPagination = (page, pageSize, itemsLength, highlightIndex) => {
  let p = page;
  if (highlightIndex != undefined) {
    const _page = Math.ceil((highlightIndex + 1) / pageSize);
    if (_page && _page > page) {
      p = _page;
    }
  }
  const start = (p - 1) * pageSize;
  let end = Math.min(start + pageSize - 1, itemsLength - 1);
  const totalPages = Math.ceil(itemsLength / pageSize);
  const res = { start, end, totalPages, page: p };
  return res;
};

const paginate = (items, page, pageSize, highlightKey, highlightValue, showAllPrev = false) => {
  let highlightIndex;

  if (highlightKey && highlightValue) {
    highlightIndex = items.findIndex(item => item[highlightKey] === highlightValue);
  }

  const { start, end } = calcPagination(page, pageSize, items.length, highlightIndex);
  const res = [...items].slice(showAllPrev ? 0 : start, end + 1);

  return res;
};

/**
 * @param {
 *          parcels: Array,
 *          monitoringDue: Number,
 *          monitoringDueAny: Boolean,
 *          monitoringDueMissing: Boolean
 * }
 */
const monitoringDueFilter = ({
  parcels,
  monitoringDue,
  monitoringDueAny,
  monitoringDueMissing,
  date
} = {}) => {
  if (!parcels) {
    return [];
  }
  if (!date) {
    date = new Date();
  }

  let res = [...parcels];

  if (monitoringDueAny) {
    return res;
  }

  if (!monitoringDueMissing) {
    res = res.filter(parcel => {
      return parcel.mdd;
    });
  }

  const Moment = require('moment');
  if (monitoringDue || monitoringDue === 0) {
    res = res.filter(parcel => {
      if (parcel.mdd) {
        const mddMonth = Math.round(
          Moment.duration(Moment(parcel.mdd).diff(Moment(date))).asMonths()
        );
        if (mddMonth < 0) {
          return true;
        }
        return mddMonth <= Number(monitoringDue);
      }
      return true;
    });
  }

  return res;
};

const teamFilter = (parcels, team) => {
  let res = [...parcels];
  if (team) {
    res = res.filter(parcel => {
      if (team == 'NONE') {
        return !parcel.team;
      } else {
        return parcel.team == team;
      }
    });
  }
  return res;
};

const landFilter = (parcels, land) => {
  let res = [...parcels];
  if (land) {
    res = res.filter(p => {
      const type = p?.metrics?.land?.information?.type;
      if (Array.isArray(land)) {
        if (land[0] === '') return true;
        return land.includes(type);
      } else {
        if (land === '') return true;
        return land === type;
      }
    });
  }
  return res;
};

const landIssueFilter = (parcels, state) => {
  let res = [...parcels];
  const ISSUE_EXISTS = Land.issueStates().ISSUE_EXISTS;
  if (state === ISSUE_EXISTS) {
    res = res.filter(p => {
      return p.landIssueState === ISSUE_EXISTS;
    });
  }
  return res;
};

const landPreserveFilter = (parcels, landPreserve) => {
  let res = [...parcels];

  if (!landPreserve) {
    return res;
  }

  res = res.filter(p => {
    const preserve = p?.metrics?.land?.information?.preserve;
    if (landPreserve === 'NONE' && !preserve) {
      return true;
    }
    if (preserve === landPreserve) {
      return true;
    } else {
      return false;
    }
  });
  return res;
};

const removePadding = (number, addExtraZero) => {
  let parcel = String(number) || '';
  if (!parcel) {
    return '';
  }
  parcel = parcel.split('-').map(e => Number(e));
  if (parcel.length < 3 && addExtraZero) {
    parcel.push('0');
  }
  parcel = parcel.join('-');
  return parcel;
};

const getAcres = data => {
  let acres = '';
  try {
    acres = (Number(data.geojson.properties.SUM_LOT_SIZE) / 44000).toFixed(1);
  } catch (err) {
    console.log(err);
  }
  return acres;
};

const toMapParcel = data => {
  if (data.indexOf('-') != -1) {
    data = data.split('-');
    if (data.length !== 4) {
      return data.join('-');
    }
    data.splice(1, 1);
    data.splice(2, 1);
    data = data.join('-');
  }
  return data;
};

const getStreetAddress = ({ street_name, street_number } = {}) => {
  let res = '';
  if (street_number) {
    res += `${street_number} `;
  }
  if (street_name) {
    res += `${street_name}`;
  }
  return res;
};

const addLeadingZero = str => {
  if (str && str.indexOf('-') > -1) {
    let text = str.replace('.', '-').split('-');
    for (let index in text) {
      while (text[index].length < 3) {
        text[index] = '0' + text[index];
      }
    }

    // while (text.length < 3) {
    //   text.push("000");
    // }

    str = text.join('-');
  }
  return str;
};

const fromMapParcel = parcelId => {
  if (parcelId.indexOf('-') == -1) {
    return parcelId;
  }
  parcelId = parcelId.split('-');
  parcelId.push('0');
  parcelId.splice(1, 0, '0');
  parcelId = parcelId.join('-');
  parcelId = addLeadingZero(parcelId);
  return parcelId;
};

const getNearbyParcels = parcels => {
  if (!parcels) {
    return {
      tct: [],
      tot: []
    };
  }

  let promises = [];

  parcels.forEach(parcel => {
    const parcelId = fromMapParcel(parcel.number);
    const url = `${api.truroAPI}/GetNeighbuttersDataApi?parcelId=${parcelId}&owner=*&geojson=1`;
    promises.push(axios.get(url));
  });

  promises = promises.map(p => p.catch(e => e));

  return new Promise(async (resolve, reject) => {
    const response = {
      tct: [],
      tot: []
    };

    let results = await Promise.all(promises);
    results = results.filter(p => !(p instanceof Error));

    results.forEach(result => {
      if (!result.data.meta || !result.data.meta.status) {
        return;
      }
      const data = result.data.data;
      Object.keys(data.tct).forEach(key => {
        const parcelData = {
          ...data.tct[key],
          number: toMapParcel(key),
          owner: 'tct',
          index: Math.floor(Math.random() * 9999) + 1000,
          acres: getAcres(data.tct[key])
        };
        response.tct.push(parcelData);
      });

      Object.keys(data.tot).forEach(key => {
        const parcelData = {
          ...data.tot[key],
          number: toMapParcel(key),
          owner: 'tot',
          index: Math.floor(Math.random() * 9999) + 1000,
          acres: getAcres(data.tot[key])
        };
        response.tot.push(parcelData);
      });
    });

    resolve(response);
  });
};

/**
 *
 * @param {Array<>} parcels
 * @returns
 */

const getNearbyCmsParcels = async parcels => {
  const cms = new CMS();
  const result = {
    tct: [],
    tot: []
  };
  const orgs = config.getOrganization();
  const geostyles = [];
  try {
    const cmsParcels = await cms.get({
      type: 'geojson'
    });

    const parcelOwner = id => {
      if (id === orgs.tct) {
        return 'tct';
      } else if (id === orgs.tot) {
        return 'tot';
      }
      return '';
    };

    if (cmsParcels.size) {
      cmsParcels.forEach(doc => {
        const data = doc.data();
        if (data.organizationId && parseGeoJSON(data.geojson)) {
          if (data.geostyle) {
            geostyles.push(cms.geostyle(data.geostyle));
          }
          const p = {
            number: fmtParcelNumber(data.parcel, 'NNN-NNN'),
            geojson: data.geojson,
            geostyle: data.geostyle,
            owner: parcelOwner(data.organizationId),
            index: Math.floor(Math.random() * 9999) + 1000
          };
          if (p.owner) {
            result[p.owner].push(p);
          }
        }
      });
    }
  } catch { }

  let geostyleData = await Promise.all(geostyles.map(p => p.catch(e => e)));
  geostyleData = geostyleData.filter(result => !(result instanceof Error));

  result.tct.forEach((p, index) => {
    if (p.geostyle) {
      const gs = geostyleData.find(g => g.data.key === p.geostyle);
      if (gs) {
        result.tct[index].geostyle = gs?.data;
      }
    }
  });

  result.tot.forEach((p, index) => {
    if (p.geostyle) {
      const gs = geostyleData.find(g => g.data.key === p.geostyle);
      if (gs) {
        result.tot[index].geostyle = gs?.data;
      }
    }
  });

  // result.tct = result.tct.filter(p => {
  //   return parcels.some(parcel => {
  //     if (parcel.geojson && p.geojson) {
  //       const geojsonA = parseGeoJSON(parcel.geojson);
  //       const geojsonB = parseGeoJSON(p.geojson);
  //       const distance = distanceBetweenPoints(geojsonA, geojsonB, 'yd');
  //       return distance < NEARBY_PARCEL_MAX_DISTANCE;
  //     } else {
  //       return false;
  //     }
  //   });
  // });

  // result.tot = result.tot.filter(p => {
  //   return parcels.some(parcel => {
  //     if (parcel.geojson && p.geojson) {
  //       const geojsonA = parseGeoJSON(parcel.geojson);
  //       const geojsonB = parseGeoJSON(p.geojson);
  //       const distance = distanceBetweenPoints(geojsonA, geojsonB, 'yd');
  //       return distance < NEARBY_PARCEL_MAX_DISTANCE;
  //     } else {
  //       return false;
  //     }
  //   });
  // });

  return result;
};

const teams = (parcels, all = false, none = false) => {
  let teams = [];
  const ALL = { text: 'All', value: '', style: { color: 'primary' } };
  const NA = { text: 'None', value: 'NONE' };

  let completed = {};

  parcels.forEach(p => {
    if (p.team && !teams.some(e => String(e.value).trim() == String(p.team).trim())) {
      completed[p.team] = true;
      teams.push({ text: p.team, value: p.team });
    }
  });

  teams.sort((a, b) => {
    return ('' + a.value).localeCompare(b.value);
  });

  if (all) {
    teams.splice(0, 0, ALL);
  }

  if (none) {
    if (parcels.some(e => !e.team)) {
      teams.push(NA);
    }
  }
  return teams;
};

/**
 *
 * @param {String} str
 */
const isParcelNumber = str => {
  if (!str) {
    return false;
  }
  if (str.includes(',') || str.includes('.')) {
    return false;
  }
  const index = str.indexOf('-');
  if (index === 0 || index === -1) {
    return false;
  }
  return true;
};

/**
 *
 * @param {String} str
 * @returns
 */

const isCoordinates = str => {
  if (!str) {
    return false;
  }
  return !!str.match(
    /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/
  );
};

/**
 *
 * @param {String} str
 */

const isReference = str => {
  const organizations = ['tct', 'tot'];
  let result = true;

  result = organizations.some(org => str.toLowerCase().includes(org));

  const split = str.split(' ');
  if (split.length === 2 && isNaN(split[0])) {
    result = true;
  }

  return result;
};

const matchParcelNumber = (number, query) => {
  return removePadding(number) === removePadding(query);
};

/**
 *
 * @param {String} str
 * @param {String} query
 */
const matchSearchQuery = (str, query) => {
  if (!str || !query) {
    return false;
  }
  return str.toLocaleLowerCase().includes(query.toLowerCase());
};

const searchParcelBy = str => {
  const searchFields = [];
  if (isParcelNumber(str)) {
    searchFields.push('number');
  } else if (isReference(str)) {
    searchFields.push('reference');
  } else {
    searchFields.push('address', 'grantor');
  }
  return searchFields;
};

const highlightString = (string, tokens) => {
  let arr = tokens;
  if (typeof tokens === 'string') {
    arr = [tokens];
  }
  let result = String(string);
  arr.forEach(token => {
    result = result.replace(new RegExp(token, 'gi'), str => `<mark>${str}</mark>`);
  });
  return result;
};

const searchParcel = (parcels, query, highlight) => {
  let results = [...parcels];

  const searchBy = ['number', 'address', 'reference', 'grantor'];

  results = results.filter(parcel => {
    let parcelInfoText = [];
    for (let key of searchBy) {
      let searchString = '';
      let searchQuery = query;

      if (key === 'number') {
        searchString = fmtParcelNumber(parcel.number, 'NN-NN');
        if (searchQuery.indexOf('-') !== -1) {
          searchQuery = fmtParcelNumber(query, 'NN-NN');
        }
      } else if (key === 'address') {
        searchString = getStreetAddress(parcel);
      } else if (key === 'reference') {
        searchString = parcel.reference;
      } else if (key === 'grantor') {
        searchString = parcel?.metrics?.land?.information?.grantor;
      }

      const data = {
        reference: parcel.reference,
        number: removePadding(parcel.number),
        address: getStreetAddress(parcel),
        grantor: parcel?.metrics?.land?.information?.grantor
      };

      const match = matchSearchQuery(searchString, searchQuery);

      if (match) {
        Object.keys(data).forEach(k => {
          if (data[k]) {
            if (k === 'number') {
              parcelInfoText.push(
                `<span class="map-parcel--text">${highlightString(data[k], searchQuery)}</span>`
              );
            } else {
              parcelInfoText.push(highlightString(data[k], searchQuery));
            }
          }
        });
      }

      if (highlight) {
        parcel.highlight = parcelInfoText.join('<br/>');
      }

      if (match) {
        return true;
      }
    }
    return false;
  });
  return results;
};

/**
 *
 * @param {Array} parcels
 * @param {{longitude, latitude}} coordinates
 * @param {'miles', 'yards'} unit
 * @returns
 */

const computeDistance = (parcels, coordinates, sortParcels = false, units = 'miles') => {
  const { center, distance, point } = require('@turf/turf');
  if (!coordinates || !coordinates?.lng) {
    return parcels;
  }

  let results = [...parcels];

  results = results.map(parcel => {
    let p = parcel;

    let to = parcel.center;
    if (!to) {
      try {
        return center(JSON.parse(parcel.geojson)).geometry.coordinates;
      } catch { }
    }

    if (!to) {
      return parcels;
    }

    const fromPoint = point([coordinates.lng, coordinates.lat]);
    const toPoint = point(to);
    const options = { units: units };

    const dst = distance(fromPoint, toPoint, options);
    p.distance = dst.toFixed(1);

    return p;
  });

  if (sortParcels) {
    results.sort((a, b) => {
      if (!a.distance || a.distance > b.distance) {
        return 1;
      } else if (!b.distance || a.distance < b.distance) {
        return -1;
      }
      return 0;
    });
  }

  return results;
};

/**
 *
 * @param {[Number, Number]} coordinates
 * @param {Number} decimal
 * @returns
 */
const coordinatesToFixed = (coordinates, decimal = 6) => {
  if (!coordinates || typeof coordinates !== 'object') {
    return [];
  }
  return coordinates.map(c => Number(c.toFixed(decimal)));
};

const parcelCenter = parcel => {
  const { center } = require('@turf/turf');
  if (parcel.center) {
    return coordinatesToFixed(parcel.center);
  }
  try {
    const pt = center(JSON.parse(parcel.geojson)).geometry.coordinates;
    return coordinatesToFixed(pt);
  } catch {
    return [];
  }
};

const recentActivityFromNow = activity => {
  const Moment = require('moment');
  Moment.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: 'seconds',
      ss: '%ss',
      m: 'a minute',
      mm: '%dm',
      h: 'an hour',
      hh: '%dh',
      d: 'a day',
      dd: '%dd',
      M: 'a month',
      MM: '%dM',
      y: 'a year',
      yy: '%dY'
    }
  });
  try {
    const ts = activity.timestamp || activity.createdAt;
    const res = Moment(parseTimestamp(ts)).fromNow(true);
    return res;
  } catch {
    return '';
  }
};

/**
 *
 * @param {Array<object>} parcels
 */
const exportParcelsToCsv = parcels => {
  const parcelsCopy = JSON.parse(JSON.stringify(parcels));
  const formattedParcels = parcelsCopy.map(parcel => {
    const p = new Parcel({ parcel });

    parcel.number = removePadding(parcel.number);
    const type = p.getLandType();
    parcel.land = Land.landType(type)?.shortName || '';
    parcel.parcel_type = p.type(parcel.parcel_type);

    const activity = new Activity(
      parcel?.recent_activity?.type,
      parcel?.recent_activity?.action,
      parcel?.recent_activity?.detail
    );

    parcel.recent_activity = `${activity.format()}\n${recentActivityFromNow(
      parcel.recent_activity
    )}`;

    parcel.grantor = parcel?.metrics?.land?.information?.grantor || '';
    parcel.binder = parcel?.metrics?.land?.information?.binder || '';
    parcel.acquiredDate = parcel?.metrics?.land?.information?.dateAcquired || '';
    const { startCase } = require('lodash');
    parcel.preserve = startCase(parcel?.metrics?.land?.information?.preserve || '');

    return p.get();
  });

  const fields = [
    { label: 'Parcel ID', value: 'number' },
    { label: 'Address', value: 'address' },
    { label: 'Type', value: 'parcel_type' },
    { label: 'Acres', value: 'acres' },
    { label: 'Key', value: 'key' },
    { label: 'Reference', value: 'reference' },
    { label: 'Last Activity', value: 'recent_activity' },
    { label: 'Land', value: 'land' },
    { label: 'Team', value: 'team' },
    { label: 'Grantor', value: 'grantor' },
    { label: 'Preserve', value: 'preserve' },
    { label: 'Binder', value: 'binder' },
    { label: 'Drive Count', value: 'driveCount' },
    { label: 'Gallery Count', value: 'galleryCount' },
    { label: 'Acquired Date', value: 'acquiredDate' }
  ];

  const { Parser } = require('json2csv');

  const opts = { fields };
  const parser = new Parser(opts);
  const csv = parser.parse(formattedParcels);
  downloadFile(`data:text/csv;charset=utf-8,${encodeURI(csv)}`, 'parcels.csv');
};

/**
 *
 * @param {*} n
 * @returns
 */

const padParcel = (n, length = 3) => {
  return String(n).padStart(length, '0');
};

/**
 *
 * @param {String} number
 * @param {('NN-NN' | 'NNN-NNN' | 'NNN-NNN-NNN-NNN', 'NN-NN-NN-NN')} fmt
 */

const fmtParcelNumber = (number, fmt = 'NN-NN') => {
  let result = '';
  let split = (number || '').split('-');

  //convert parcel number to NNN-NNN-NNN format
  if (split.length === 0) {
    split.push(...[0, 0, 0, 0]);
  } else if (split.length === 1) {
    split.push(...[0, 0, 0]);
  } else if (split.length === 2) {
    split = [split[0], 0, split[1], 0];
  } else if (split.length === 3) {
    split.push(0);
  }
  split = split.map(item => padParcel(item));

  if (fmt === 'NN-NN') {
    result = [split[0], split[2]].map(i => parseInt(i));
  } else if (fmt === 'NNN-NNN') {
    result = [split[0], split[2]];
  } else if (fmt === 'NN-NN-NN-NN') {
    result = split.map(i => parseInt(i));
  } else if (fmt === 'NNN-NNN-NNN-NNN') {
    result = split;
  } else {
    throw new Error('Invalid parcel number format');
  }

  return result.join('-');
};

const parseAcres = (area, unit = 'sqm', decimal = 2) => {
  let acres = parseFloat(area);

  if (area === undefined || area === null) {
    return area;
  }

  if (unit === 'sqm') {
    acres = Number(acres) / 4047;
  }

  return +acres.toFixed(decimal);
};

export {
  isLandOnlyParcel,
  calcPagination,
  paginate,
  monitoringDueFilter,
  teamFilter,
  landFilter,
  removePadding,
  getStreetAddress,
  getNearbyParcels,
  fromMapParcel,
  addLeadingZero,
  teams,
  searchParcel,
  isParcelNumber,
  isCoordinates,
  isReference,
  computeDistance,
  parcelCenter,
  exportParcelsToCsv,
  recentActivityFromNow,
  landPreserveFilter,
  getNearbyCmsParcels,
  fmtParcelNumber,
  padParcel,
  parseAcres,
  landIssueFilter
};
